<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <v-card outlined id="container-details">
              <v-card-title class="my-0"> Container Details </v-card-title>
              <v-card-text class="my-0 py-0">
                <v-list dense>
                  <v-row>
                    <!-- <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-if="item.containerNo">{{
                              item.containerNo
                            }}</span><span v-else>-</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Container No.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-if="item.sealNo">{{ item.sealNo }}</span><span v-else>-</span>
                          </v-list-item-title>
                          <v-list-item-subtitle> Seal No. </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-if="item.booking.carrierReferenceNumber">{{
                              item.booking.carrierReferenceNumber
                            }}</span><span v-else>-</span>
                          </v-list-item-title>
                          <v-list-item-subtitle> Booking No. </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-if="item.booking.contractNumber">{{
                              item.booking.contractNumber
                            }}</span><span v-else>-</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Booking Contract No.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-if="item.booking.shipperReferenceNumbers">{{
                              item.booking.shipperReferenceNumbers
                            }}</span><span v-else>-</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Reference No.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>{{ formatNumber(sumGrossWeight.toFixed(2)) }} Kg</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Gross Weight
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>{{ formatNumber(sumNettWeight.toFixed(2)) }} Kg</span>
                          </v-list-item-title>
                          <v-list-item-subtitle> Nett Weight </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col> -->
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-list-item v-if="item.invoiceNo">
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>{{ item.invoiceNo }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle> Invoice No. </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-list-item v-if="item.invoiceDate">
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>{{ item.invoiceDate }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Invoice Date
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" class="my-0">
                      <v-subheader style="font-size: 16px; background-color: var(--v-greyBase-base)" v-if="item.containerMetadata &&
                        item.containerMetadata.length > 0
                        ">Additional Detail</v-subheader>
                      <v-list-item style="height: 20px" v-for="meta in item.containerMetadata" :key="meta.id">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ meta.key }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon v-if="meta.type == 'Check' && meta.value == 'Passed'"
                            color="success">check_circle_outline</v-icon>
                          <v-icon v-else-if="meta.type == 'Check' && meta.value == 'Failed'
                            " color="red">cancel</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <div :key="documentLoadComplete" v-if="removalPermitAttached">
                        <v-subheader style="font-size: 16px; background-color: var(--v-greyBase-base)">Removal
                          Permits <v-spacer></v-spacer> <v-btn @click="addRemovalPermit()" color="primary"
                            icon><v-icon>add_circle_outline</v-icon></v-btn>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn color="blue" v-on="on" icon :loading="loadingContainerDocumentPack"
                                @click="createContainerDocumentPack()"><v-icon>picture_as_pdf</v-icon></v-btn>
                            </template>
                            <span style="font-size: 12px">Create Removal Permit Pack</span>
                          </v-tooltip>
                        </v-subheader>
                        <v-list-item v-for="(permit, index) in removalPermitFarms" :key="index">
                          <v-list-item-action>
                            <v-icon v-if="permit && new Date(permit.expiry) > new Date()" color="success">check_circle_outline</v-icon>
                            <v-icon v-else color="red">cancel</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ permit.farm }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ permit.permitNo }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="permit.expiry">
                              <span v-if="new Date(permit.expiry) > new Date()">Valid until: {{ permit.expiry }}</span>
                              <span v-else style="color: red">Expired: {{ permit.expiry }}</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-row justify="center">
                              <v-btn v-if="permit.permitDocument" icon
                                @click="viewRemovalPermit(permit.permitDocument, permit.farm)"><v-icon>launch</v-icon></v-btn>
                            </v-row>
                          </v-list-item-action>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8">
            <v-card flat id="shipment-documents">
              <v-card-title class="my-0"> Shipment Documents </v-card-title>
              <v-card-text class="mx-0 px-0">
                <v-data-table :items="documents" :headers="headers" :loading="documentLoading" fixed-header flat
                  disable-pagination dense @click:row="viewDocument" hide-default-footer max-height="30vh"
                  class="elevation-0 py-0 my-0" style="cursor: pointer; box-shadow: none !important">
                  <template v-slot:[`item.status`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" :color="getStatusColor(item.status)">{{
                          getStatusIcon(item.status)
                        }}</v-icon>
                      </template>
                      <span style="font-size: 12px">{{ item.status }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.integrationStatus`]="{ item }">
                    <v-tooltip top :key="documentUpdateKey" v-if="item.integrationStatus">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" :color="getStatusColor(item.integrationStatus)">{{
                          getStatusIcon(item.integrationStatus) }}</v-icon>
                      </template>
                      <span style="font-size: 12px">{{ item.integrationStatus }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.locationDocument.name`]="{ item }">
                    <v-tooltip top v-if="item.manuallyAdded">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small color="blue">verified</v-icon>
                      </template>
                      <span style="font-size: 12px">Manually added document</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.comment">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small color="blue">description</v-icon>
                      </template>
                      <span style="font-size: 12px">Notes: {{ item.comment }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.specialInstruction">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small color="yellow">star</v-icon>
                      </template>
                      <span style="font-size: 12px">Special Instructions: {{ item.specialInstruction }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.attest">
                      <template v-slot:activator="{ on }">
                        <v-icon small color="orange" v-on="on">rule</v-icon>
                      </template>
                      <span style="font-size: 12px">
                        Attest
                      </span>
                    </v-tooltip>
                    {{ item.locationDocument.name }}
                    <v-tooltip top v-if="item.attestRequired">
                      <template v-slot:activator="{ on, attrs }">
                        <i v-bind="attrs" v-on="on" style="color: red">(Attest Required)</i>
                      </template>
                      <span style="font-size: 12px"> </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.submittedDue`]="{ item }">
                    <span v-if="item.submittedDue && !item.submittedDate"
                      :style="{ color: new Date(item.submittedDue) < new Date() ? 'orange' : 'primaryText' }">
                      {{ item.submittedDue }}
                    </span>
                    <span v-else-if="item.submittedDue" style="color: var(--v-success-base)">{{ item.submittedDue
                    }}</span>
                  </template>
                  <template v-slot:[`item.completedDue`]="{ item }">
                    <span v-if="item.completedDue && !item.completedDate"
                      :style="{ color: new Date(item.completedDue) < new Date() ? 'orange' : 'primaryText' }">
                      {{ item.completedDue }}
                    </span>
                    <span v-else-if="item.completedDue" style="color: var(--v-success-base)">{{ item.completedDue
                    }}</span>
                  </template>

                  <template v-slot:[`item.quantity`]="{ item }">
                    <v-tooltip top v-if="item.quantityType == 'Shipment'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" icon outlined small class="mx-1" v-on="on">
                          <v-icon small>directions_boat</v-icon>
                        </v-btn>
                      </template>
                      <span style="font-size: 12px; text-transform: capitalize">{{
                        item.quantityType
                      }}</span>
                    </v-tooltip>

                    <v-tooltip top v-else-if="item.quantityType == 'Container'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" icon outlined small class="mx-1" v-on="on" color="orange">
                          <v-icon small>widgets</v-icon>
                        </v-btn>
                      </template>
                      <span style="font-size: 12px; text-transform: capitalize">{{
                        item.quantityType
                      }}</span>
                    </v-tooltip>

                    <v-tooltip top v-else-if="item.quantityType == 'CountContainer'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" icon outlined small class="mx-1" v-on="on" color="blue">
                          <v-icon small>view_module</v-icon>
                        </v-btn>
                      </template>
                      <span style="font-size: 12px; text-transform: capitalize">
                        Per {{ item.count }} Containers</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.responsiblePartyType`]="{ item }">
                    <v-tooltip top v-if="item.responsiblePartyType">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" icon outlined small class="mx-1" v-on="on">
                          <v-icon small>{{
                            getPartyIcon(item.responsiblePartyType)
                          }}</v-icon>
                        </v-btn>
                      </template>
                      <span style="font-size: 12px; text-transform: capitalize">{{
                        item.responsiblePartyType
                      }}</span>
                    </v-tooltip>
                  </template>


                  <template v-slot:[`item.courier`]="{ item }">
                    <v-icon v-if="item.courier" color="success">check</v-icon>
                  </template>

                </v-data-table>

              </v-card-text>
            </v-card>
            <v-card flat id="uploaded-documents">
              <v-card-title class="my-0"> Uploaded Documents </v-card-title>
              <v-progress-linear v-if="loadingDocuments" indeterminate color="primary"></v-progress-linear>

              <v-card-text class="my-0 py-0" :key="documentUpdateKey">
                <v-row>
                  <v-col cols="12" v-if="!loadingDocuments && filterDocuments == 0" class="text-center">
                    <span style="color: grey">No documents found.</span></v-col>
                  <v-col cols="12" sm="4" v-for="note in filterDocuments" :key="note.id">
                    <v-hover v-slot:default="{ hover }">
                      <v-card style="border-radius: 20px" outlined class="pa-0 ma-0">
                        <v-expand-transition>
                          <v-card v-if="hover"
                            class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal display-3 white--text"
                            style="height: 50%">
                            <v-card-text class="py-0 my-0">
                              <v-row justify="space-around">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="white" @click="downloadDocument(note)"
                                      :loading="note.loading"><v-icon>download</v-icon></v-btn>
                                  </template>
                                  <span style="font-size: 12px">Download</span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon color="white" @click="viewRemovalPermit(note)"
                                      :loading="loadingPermit.includes(note.id)"><v-icon>launch</v-icon></v-btn>
                                  </template>
                                  <span style="font-size: 12px">Preview</span>
                                </v-tooltip>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-expand-transition>
                        <v-card-text class="my-0 mx-0 px-0">
                          <v-row justify="center">
                            <v-col cols="12" class="my-0 py-0">
                              <v-list-item class="my-0 py-0">
                                <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                                  <v-img class="mr-2" style="height: 30px; width: 30px" :src="getIcon(note.name)">
                                  </v-img>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title class="text-left" style="font-size: 14px">
                                    {{ note.name }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle style="font-size: 12px" v-if="note.user">
                                    <v-icon small>person</v-icon>
                                    {{ note.user.firstname }}
                                    {{ note.user.surname }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle style="font-size: 12px" v-else>
                                    System Generated
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle style="font-size: 12px" v-if="note.isFinancialDocument">
                                    <v-icon small class="mr-1">account_balance</v-icon>
                                    Financial Document
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle style="font-size: 12px">
                                    {{ formatDate(note.createdAt) }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card flat v-if="auditDocuments.length > 0">
              <v-card-title class="my-0">Audit Documents </v-card-title>
              <v-card-text class="my-0 py-0" style="min-height: 15vh; max-height: 25vh; overflow-y: auto"
                v-loading="loadingAuditDocuments" :key="documentUpdateKey">
                <v-row>
                  <v-col cols="12" v-if="!loadingAuditDocuments && auditDocuments == 0" class="text-center">
                    <span style="color: grey">No documents found.</span></v-col>

                  <v-col cols="12" sm="4" v-for="note in auditDocuments" :key="note.id">
                    <v-hover v-slot:default="{ hover }">
                      <v-card style="border-radius: 20px" outlined class="pa-0 ma-0" :elevation="hover ? 16 : 0"
                        @click="viewAuditedDocument(note)">
                        <v-card-text class="my-0 mx-0 px-0">
                          <v-row justify="center">
                            <v-col cols="12" class="my-0 py-0">
                              <v-list-item class="my-0 py-0">
                                <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                                  <v-img class="mr-2" style="height: 30px; width: 30px" :src="getIcon(note.name)">
                                  </v-img>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title class="text-left" style="font-size: 14px">
                                    {{ note.name }}
                                  </v-list-item-title>

                                  <v-list-item-subtitle style="font-size: 12px" v-for="pallet in note.palletDocuments"
                                    :key="pallet.id">
                                    {{ pallet.sscc }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="mediaModal" width="1200px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="task && task.taskType">
        <v-toolbar color="transparent" flat>
          <v-toolbar-title>
            <v-icon>folder</v-icon>
            Media: {{ task.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(mediaModal = false), (task = {})">X</v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 40vh; overflow-y: auto">
          <v-row>
            <v-col cols="12" sm="4" v-for="media in task.taskAttachments" :key="media.id">
              <v-card style="border-radius: 20px" outlined>
                <v-card-text class="my-0">
                  <v-row justify="center">
                    <v-col cols="12" class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-action>
                          <v-img style="width: 40px; height: 40px" :src="getIcon(media.name)">
                          </v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="text-left" style="font-size: 16px">
                            {{ media.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action :key="documentDownloadKey">
                          <v-row justify="center">
                            <v-btn icon @click="downloadDocument(media)"
                              :loading="media.loading"><v-icon>download</v-icon></v-btn>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="taskModal" width="1200px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <TaskModal :task="task" @close="(taskModal = false), (task = {})" />
    </v-dialog>

    <v-dialog v-model="documentModal" fullscreen>
      <DocumentModal @close="documentModal = false" :item="shipment" :actions="actions" :bookings="bookings"
        :documentItem="document" :tasks="documentTasks" :documents="documentTypes" :documentTypes="documentTypes"
        :uploads="documentUploads" @updateDocument="updateDocument" />
    </v-dialog>

    <v-dialog v-model="auditModal" width="1200px" :fullscreen="$vuetify.breakpoint.mobile || fullscreen">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{ auditDocument.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn v-if="auditDocument.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="auditDocument.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn> -->
          <v-btn v-if="auditDocument.name" text @click="$refs.pdf_1[0].print()">
            <v-icon>print</v-icon>
          </v-btn>
          <v-btn @click="(fullscreen = !fullscreen)" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon @click="downloadAuditDocument(auditDocument)"
            :loading="auditDocument.loading"><v-icon>download</v-icon></v-btn>
          <v-btn text @click="(auditModal = false), (auditDocument = {})">X</v-btn>
        </v-toolbar>
        <v-card-text v-loading="auditLoading" :style="{
          'max-height': fullscreen ? '88vh' : '75vh',
          'overflow-y': 'auto',
        }">
          <v-row justify="center">
            <v-col cols="12" md="9" lg="8" xl="6" class="text-center">
              <div ref="pdfBox" :key="pdfRefreshKey">
                <pdf :ref="'pdf_' + page" :src="auditDocument.file" v-for="page in auditDocument.pages" :key="page"
                  :annotation="true" :rotate="rotate" :page="page"></pdf>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="permitModal" :fullscreen="$vuetify.breakpoint.mobile || fullscreen" width="1200px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
            {{ documentItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon :loading="downloadingPermit" @click="downloadPermit(documentItem)"><v-icon>download</v-icon></v-btn>
          <v-btn icon @click="(permitModal = false), (documentItem = {})">X</v-btn>
        </v-toolbar>
        <v-progress-linear v-if="loadingPermit.includes(documentItem.id)" indeterminate
          color="primary"></v-progress-linear>
        <v-card-text :style="{
          'max-height': fullscreen ? '88vh' : '75vh',
          'overflow-y': 'auto',
          'background-color': 'var(--v-modal-base) !important',
        }">
          <v-row justify="center">
            <v-col cols="12" md="9" lg="8" xl="6" class="text-center">
              <div ref="pdfBox">
                <VuePdfEmbed :width="fullscreen ? 1200 : 800" :source="documentItem.url" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removalPermitModal" width="600px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>
          Attach Removal Permit: {{ removalPermitItem.farm }} <v-spacer></v-spacer><v-btn text
            @click="removalPermitModal = false, removalPermitItem = {}">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="12" v-if="!removalPermitItem.selectedDocument || removalPermitPage == 0"
              :key="removalPermitFormKey">
              <v-list>
                <v-subheader style="font-size: 16px">Select a Document
                  <!-- <v-btn class="mx-1" icon small color="blue" :loading="uploadingFile" @click="addPermitFiles()"><v-icon
                  class="ml-2">cloud_upload</v-icon>
                </v-btn> -->
                </v-subheader>
                <v-list-item v-if="filterDocuments.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No documents</span>
                  </v-list-item-content></v-list-item>
                <v-list dense style="max-height: 45vh; overflow-y: auto">
                  <v-list-item v-for="document in filterDocuments" :key="document.id"
                    @click="removalPermitItem.selectedDocument = document, removalPermitFormKey++, removalPermitPage = 1"
                    :style="{ 'border-left': removalPermitItem.selectedDocument && document.id == removalPermitItem.selectedDocument.id ? '3px solid var(--v-primary-base)' : '' }">
                    <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                      <v-img class="mr-2" style="height: 30px; width: 30px" :src="getIcon(document.name)">
                      </v-img>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="text-left" style="font-size: 14px">
                        {{ document.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px" v-if="document.user">
                        <v-icon small>person</v-icon>
                        {{ document.user.firstname }}
                        {{ document.user.surname }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px" v-else>
                        System Generated
                      </v-list-item-subtitle>

                      <v-list-item-subtitle style="font-size: 12px" v-if="document.isFinancialDocument">
                        <v-icon small class="mr-1">account_balance</v-icon>
                        Financial Document
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        {{ formatDate(document.createdAt) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-row justify="center" class="mt-2">

                  <v-btn class="mt-2" outlined :disabled="!removalPermitItem.selectedDocument" color="primary"
                    @click="removalPermitPage++">Next <v-icon right small>chevron_right</v-icon></v-btn>
                </v-row>

                <file-upload class="my-0 py-0" post-action="" :multiple="false" :drop="true" :drop-directory="true"
                  v-model="removalPermitList" ref="permitUploader">
                </file-upload>
              </v-list>
            </v-col>
            <v-col cols="12" v-else-if="removalPermitPage == 1">
              <v-subheader>Select applicable PUC's</v-subheader>

              <v-list style="max-height: 40vh; overflow-y: auto">
                <v-list-item v-for="(permit, index) in removalPermitFarms" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ permit.farm }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox v-model="removalPermitItem.selectedFarms" :value="permit.farm"
                      @change="removalPermitFormKey++"></v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-row justify="center">
                <v-btn class="mt-2 mx-1" outlined color="primary" @click="removalPermitPage--"> <v-icon left
                    small>chevron_left</v-icon> Back</v-btn>
                <v-btn class="mt-2 mx-1" outlined :disabled="removalPermitItem.selectedFarms.length == 0" color="primary"
                  @click="removalPermitPage++">Next <v-icon right small>chevron_right</v-icon></v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" v-else-if="removalPermitPage == 2" class="text-center">
              <v-text-field label="Permit No" v-model="removalPermitItem.permitNo" outlined clearable
                dense></v-text-field>
              <v-subheader>Select Expiry Month:</v-subheader>
              <v-date-picker v-model="removalPermitItem.selectedDate" type="month"></v-date-picker>
              <v-row justify="center" class="my-2">
                <v-btn class="mt-2 mx-1" outlined color="primary" @click="removalPermitPage--"><v-icon small
                    left>chevron_left</v-icon> Back</v-btn>
                <v-btn class="mt-2 mx-1" outlined
                  :disabled="!removalPermitItem.permitNo || !removalPermitItem.selectedDate" color="success"
                  :loading="submittingPermit" @click="submitRemovalPermit()">Submit</v-btn>
              </v-row>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card></v-dialog>
    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Container - Overview!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Help Guide/ Tutorial -->
    <v-btn id="info-guide" @click="infoStartGuide" fab height="4vh" width="4vh"
      style="position: absolute; z-index: 5; right: 1vw; top: 1vh">
      <v-icon>help</v-icon>
    </v-btn>
  </div>
</template>
<script>
import axios from 'axios'
import dateFormat from "dateformat";
import DocumentModal from "../../SystemComponents/DocumentModal.vue";
import FileUpload from "vue-upload-component";

import pdf from "vue-pdf";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
// import pdf from 'pdfvuer'
// import PDFViewer from '../../SystemComponents/PDFViewer.vue'
import TaskModal from "../../SystemComponents/TaskModal.vue";

import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
// import steps from './Steps/containerOverviewSteps'

export default {
  props: ["item"],
  components: {
    // PDFViewer,
    DocumentModal,
    FileUpload,
    pdf,
    TaskModal,
    VuePdfEmbed
  },
  data: () => ({
    steps: null,
    actions: [],
    activeTasks: [],
    auditDocuments: [],
    auditDocument: {},
    auditLoading: false,
    auditModal: false,
    bookings: [],
    currentPage: 1,
    document: {},
    documentTasks: [],
    documentUploads: [],
    documentLoading: false,
    documents: [],
    documentDownloadKey: 0,
    documentUpdateKey: 0,
    documentLoadComplete: 1000,
    downloadingPermit: false,
    documentItem: {},
    documentModal: false,
    documentTypes: [],
    expandedProfile: null,
    fullscreen: false,
    filterDocuments: [],
    headers: [
      {
        text: "Status",
        value: "status",
        align: "center",
        width: "75px",
      },
      {
        text: "Integration",
        value: "integrationStatus",
        align: "center",
        width: "50px",
        groupable: false,
      },
      {
        text: "Name",
        value: "locationDocument.name",
        align: "left",
        width: "150px",
        groupable: false,
      },
      {
        text: "Reference",
        value: "reference",
        align: "left",
        groupable: false,
      },
      {
        text: "Responsible Party",
        value: "responsiblePartyType",
        align: "center",
        groupable: false,
      },
      {
        text: "Quantity",
        value: "quantity",
        align: "center",
        groupable: false,
        sortable: false
      },

      {
        text: "Submitted Date",
        value: "submittedDate",
        align: "center",
        sortable: false,
      },
      {
        text: "Completed Date",
        value: "completedDate",
        align: "center",
        sortable: false,
      },
      {
        text: "Rank",
        value: "rank",
        align: "center",
        groupable: false,
        width: "50px",
      },
      {
        text: "Regulatory Body",
        value: "locationDocument.regulatoryBody.name",
        align: "left",
        groupable: false,
      },
      {
        text: "Courier",
        value: "courier",
        align: "center",
        width: "50px",
      }
    ],
    integrationTypes: [
      { name: "Manual Template", value: "manual" },
      { name: "REST API", value: "restapi" },
      { name: "SOAP API", value: "soap" },
      { name: "EDIFACT", value: "edifact" },
    ],
    itemKey: 1000,
    loadingAuditDocuments: false,
    loadingContainerDocumentPack: false,
    loadingDocuments: false,
    loadingPermit: [],
    loadingTasks: false,
    loadingTaskLogs: false,
    mediaModal: false,
    pageCount: [],
    permitModal: false,
    pdfRefreshKey: 0,
    removalPermitItem: {},
    removalPermitModal: false,
    removalPermitList: [],
    removalPermitPage: 0,
    removalPermitFormKey: 2000,
    rotate: 0,
    shipment: {},
    submittingPermit: false,
    tasks: [],
    task: {},
    taskUpdateKey: 0,
    taskModal: false,
    uploadingFile: false,
    infoStart: false
  }),
  computed: {
    pendingDocuments() {
      return this.documents.filter((d) => d.submittedCount < d.requiredCount);
    },
    submittedDocuments() {
      return this.documents.filter((d) => d.submittedCount < d.approvedCount);
    },
    completeDocuments() {
      return this.documents.filter(
        (d) =>
          d.submittedCount == d.approvedCount &&
          d.requiredCount == d.approvedCount
      );
    },
    removalPermitAttached() {
      let find = this.documents.filter(x => x.locationDocument && x.locationDocument.name == 'Bactrocera Dorsalis Removal Permit')
      return find.length > 0
    },
    removalPermitFarms() {
      let uniqueFarms = [...new Set(this.item.containerPallets.map(x => x.farm))]
      let result = []
      for (let i = 0; i < uniqueFarms.length; i++) {
        let find = this.item.containerPallets.find(x => x.farm == uniqueFarms[i] && x.permitDocumentId)
        if (find) {
          result.push({
            permitDocumentId: find.permitDocumentId,
            farm: uniqueFarms[i],
            permitNo: find.permitNo,
            expiry: find.permitExpiryDate,
            permitDocument: find.permitDocument
          })
        } else {
          result.push({
            farm: uniqueFarms[i],
            permitNo: null,
            expiry: null
          })
        }
      }
      return result
    },
    sumNettWeight() {
      return this.item.containerPallets
        .map((x) => parseFloat(x.nettWeight))
        .reduce((a, b) => a + b);
    },
    sumGrossWeight() {
      return this.item.containerPallets
        .map((x) => parseFloat(x.grossWeight))
        .reduce((a, b) => a + b);
    },
  },
  watch: {
    removalPermitList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.submitPermitFiles();
        }
      },
    },
    "$route.params.shipmentId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.$socket.send(
            JSON.stringify({
              action: "join",
              room: "shipment_" + val,
              user: {
                id: this.$store.state.user.id,
                name:
                  this.$store.state.user.firstname +
                  " " +
                  this.$store.state.user.surname,
                avatar: this.$store.state.user.avatar,
                company: this.$store.state.currentOrg.name,
              },
            })
          );
        }
      },
    },
    "item.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadContainer();
        }
      },
    },
    fullscreen() {
      if (this.auditModal) {
        this.annotate()
      }
    }
  },
  async mounted() {
    this.driver = new Driver({
      animate: false
    })
    this.$socket.onopen = () => {
      console.log("socket opened");
      this.$socket.send(
        JSON.stringify({
          action: "join",
          room: "shipment_" + this.$route.params.shipmentId,
          user: {
            id: this.$store.state.user.id,
            name:
              this.$store.state.user.firstname +
              " " +
              this.$store.state.user.surname,
            avatar: this.$store.state.user.avatar,
            company: this.$store.state.currentOrg.name,
          },
        })
      );
    };
    this.$socket.onmessage = (data) => {
      let message = JSON.parse(data.data);
      this.handleMessage(message);
    };
  },
  unmounted() {
    delete this.$socket.onmessage;
  },
  async created() {
    await this.getSteps();
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'ContainerSummary/Document/Steps',
        });
        this.steps = steps.steps
        },
    addPermitFiles() {
      this.$refs.permitUploader.$el.children[0].click();
    },
    addRemovalPermit() {
      this.removalPermitItem = {
        selectedDocument: null,
        selectedFarms: [],
        permitNo: null,
        selectedDate: null
      }
      this.removalPermitModal = true
    },
    async createContainerDocumentPack() {
      this.loadingContainerDocumentPack = true
      let result = await this.$API.createDocumentPack({
        type: 'permitDocument',
        bookingContainerId: this.item.id,
        shipmentId: this.$route.params.shipmentId
      })
      this.filterDocuments.push(result)
      this.$message.success('Removal Permit pack successfully created!')
      this.loadingContainerDocumentPack = false
    },
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case "open":
          return "grey";
        case "not required":
          return "grey";
        case "not started":
          return "grey";
        case "approved":
          return "green";
        case "complete":
          return "green";
        case "in progress":
          return "indigo";
        case "submitted":
          return "blue";
        case "resubmitted":
          return "cyan";
        case "draft":
          return "orange";
        case "file generated":
        return "orange";
        case "query":
          return "purple";
        case 'in transnit':
          return 'orange'
        case "pending":
          return "orange";
        case status.includes("pending"):
          return "orange";
        case "draft approved":
          return "purple";
        case "revision required":
          return "red";
        case "error":
          return "red";
        case "not created":
          return "red";
        default:
          return "grey";
      }
    },
    getStatusIcon(status) {
      switch (status.toLowerCase()) {
        case "not required":
          return "close";
        case "not started":
          return "schedule";
        case "pending":
          return "pending";
        case "in progress":
          return "sync";
        case "draft":
          return "edit_note";
        case "submitted":
          return "mark_email_read";
        case "resubmitted":
          return "mark_email_read";
        case "complete":
          return "task_alt";
        case "file generated":
          return "task";
        case "error":
          return "cancel";
        case "not created":
          return 'error';
        case 'approved':
          return 'fact_check'
        default:
          return "info";
      }
    },
    startAnnotate() {
      for (let i = 0; i < this.auditDocument.pages; i++) {
        var canvas = this.$refs["pdf_" + (i + 1)][0].$refs.canvas;
        let width = this.$refs["pdf_" + (i + 1)][0].$refs.canvas.width;
        let height = this.$refs["pdf_" + (i + 1)][0].$refs.canvas.height;
        console.log(this.$refs["pdf_" + (i + 1)], width, height);

        let ctx = canvas.getContext("2d");
        ctx.lineWidth = "2";
        ctx.strokeStyle = "red";
        let pallets = this.auditDocument.palletDocuments.filter(
          (x) => x.page == i + 1
        );
        for (let j = 0; j < pallets.length; j++) {
          let pallet = pallets[j];
          if (pallet.geometry) {
            let palletBox = pallet.geometry.BoundingBox;
            let left = palletBox.Left * width;
            let top = palletBox.Top * height - 5;
            let boxWidth = palletBox.Width * width;
            let boxHeight = palletBox.Height * height + 10;
            console.log("Pallet", pallet.sscc, "Page", i + 1);
            console.log("Height", height);
            console.log("width", width);
            console.log("Bounding box", left, top, boxWidth, boxHeight);
            ctx.beginPath();
            ctx.rect(left, top, boxWidth, boxHeight);
            ctx.stroke();
          }
        }
        ctx.stroke();
      }
    },
    annotate() {
      this.pdfRefreshKey++;

      setTimeout(() => {
        this.startAnnotate();
      }, 2000);
      // this.pdfRefreshKey++
    },
    async downloadAuditDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    async downloadDocument(doc) {
      doc.loading = true;
      this.documentDownloadKey++;
      let file = await this.$API.downloadFile({
        key: doc.key ?? doc.file,
      });
      doc.file = file.file;
      doc.fileType = file.fileType;
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
      doc.loading = false;
      this.documentDownloadKey++;
    },
    async downloadPermit(doc) {
      this.downloadingPermit = true
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
      this.downloadingPermit = false
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "dd/mm/yyyy HH:MM");
      }
      return result;
    },
    formatNumber(number) {
      return number.toLocaleString(undefined, { minimumFractionDigits: 0 });
    },
    getDocumentIntegration(document) {
      let integrationDocument = document;
      if (document.variations && document.variations.length > 0) {
        integrationDocument = document.variations[0];
      }
      switch (integrationDocument.locationDocument.integrationType) {
        case "restapi":
          return { name: "API", icon: "api" };
        case "manual":
          return { name: "Manual Template", icon: "description" };
        default:
          return {
            name: this.integrationTypes.find(
              (x) =>
                x.value == integrationDocument.locationDocument.integrationType
            ).name,
            icon: "webhook",
          };
      }
    },
    getPartyIcon(type) {
      switch (type.toLowerCase()) {
        case "buyer":
          return "shopping_cart";
        case "consignee":
          return "call_received";
        case "shipper":
          return "directions_boat";
        case "forwarder":
          return "fast_forward";
        case "notify party":
          return "notifications";
      }
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1).toLowerCase();
      }
      if (result && result.toLowerCase() == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    getTaskStatus(task) {
      switch (task.status) {
        case "Pending":
          return { name: task.status, icon: "schedule", color: "grey" };
        case "In Progress":
          return { name: task.status, icon: "pending", color: "blue" };
        case "Complete":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "Success":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "Cancelled":
          return { name: task.status, icon: "cancel", color: "grey" };
        case "Error":
          return { name: task.status, icon: "cancel", color: "red" };
        case "File Generated":
          return { name: task.status, icon: "task_alt", color: "orange" };
        default:
          return {
            icon: "info",
            color: "grey",
            name: task.status,
          };
      }
    },
    async handleMessage(message) {
      if (typeof message == "string") {
        message = JSON.parse(message);
      }
      if (
        message.type == "task_update" &&
        message.room == "shipment_" + this.$route.params.shipmentId
      ) {
        if (message.parentTaskId) {
          let findTask = this.tasks.findIndex(
            (x) => x.id == message.parentTaskId
          );
          if (findTask > -1) {
            let childTask = this.tasks[findTask].childTasks.findIndex(
              (x) => x.id == message.id
            );
            if (childTask > -1) {
              if (message.data) {
                if (typeof message.data == "string") {
                  message.data = JSON.parse(message.data);
                }
                this.tasks[findTask].childTasks[childTask] = {
                  ...this.tasks[findTask].childTasks[childTask],
                  ...message.data,
                };
              }
            }
          }
        } else {
          let findTask = this.tasks.findIndex((x) => x.id == message.id);
          if (findTask > -1) {
            if (message.data) {
              if (typeof message.data == "string") {
                message.data = JSON.parse(message.data);
              }
              this.tasks[findTask] = {
                ...this.tasks[findTask],
                ...message.data,
              };
            }
          }
        }
        this.taskUpdateKey++;
        this.activeTasks = this.tasks.filter((x) => x.isActive);
      } else if (
        message.type == "document_update" &&
        message.room == "shipment_" + this.$route.params.shipmentId
      ) {
        let updatedDocument = await this.$API.getShipmentDocumentTypeDetail(
          message.id
        );
        if (message.parentShipmentDocumentId) {
          let findTask = this.documents.findIndex(
            (x) => x.id == message.parentShipmentDocumentId
          );
          if (findTask > -1) {
            let childTask = this.documents[findTask].variations.findIndex(
              (x) => x.id == message.id
            );
            if (childTask > -1) {
              this.documents[findTask].variations[childTask] = {
                ...this.documents[findTask].variations[childTask],
                ...updatedDocument,
              };
            }
          }
        } else {
          let findTask = this.documents.findIndex((x) => x.id == message.id);
          if (findTask > -1) {
            this.documents[findTask] = {
              ...this.documents[findTask],
              ...updatedDocument,
            };
          }
        }
        this.documentUpdateKey++;
      } else if (
        message.type == "new_task_document" &&
        message.room == "shipment_" + this.$route.params.shipmentId
      ) {
        let findParentTask = this.tasks.findIndex((x) => x.id == message.id);
        if (findParentTask) {
          if (message.data) {
            if (typeof message.data == "string") {
              message.data = JSON.parse(message.data);
            }
            let findAttachment = this.tasks[
              findParentTask
            ].taskAttachments.findIndex((x) => x.id == message.data.id);
            if (findAttachment == -1) {
              this.tasks[findParentTask].taskAttachments.push(message.data);
              this.taskUpdateKey++;
            }
          }
        }
      }
    },
    async loadDocuments() {
      this.documentLoading = true
      this.documents = await this.$API.getContainerDocumentTypes(
        this.item.id,
        this.$route.params.shipmentId
      );
      this.documentLoadComplete++
      this.documentLoading = false
      this.loadingDocuments = true;
      this.filterDocuments = await this.$API.getContainerDocuments(
        this.item.id
      );
      this.loadingDocuments = false;
    },
    async loadTasks() {
      this.loadingTasks = true;
      this.tasks = await this.$API.getContainerTasks(
        this.item.id,
        this.$route.params.shipmentId
      );
      this.activeTasks = this.tasks.filter((x) => x.isActive);
      this.loadingTasks = false;
    },

    async loadContainer() {
      this.loadDocuments();
      // this.loadTasks();
    },
    async submitPermitFiles() {
      this.uploadingFile = true
      let pending = this.removalPermitList;
      for (let i = 0; i < pending.length; i++) {
        let url = await URL.createObjectURL(pending[i].file);
        this.toDataUrl(url, (data) => {
          let obj = {
            name: pending[i].name,
            data: data,
            type: pending[i].type,
            fileIndex: i,
            shipmentId: this.$route.params.shipmentId,
          };
          this.submitUpload(obj);
        });
      }
    },
    async submitRemovalPermit() {
      this.removalPermitItem.permitDocumentId = this.removalPermitItem.selectedDocument.id
      let expiry = this.removalPermitItem.selectedDate.split('-')
      this.removalPermitItem.permitExpiryDate = new Date(expiry[0], expiry[1], 0)
      this.submittingPermit = true
      await this.$API.submitRemovalPermit(this.removalPermitItem)
      this.$message.success("Successfully updated removal permit!")
      this.removalPermitModal = false
      this.removalPermitItem = {}
      this.submittingPermit = false
      this.$emit('refresh')
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async submitUpload(obj) {
      let Api = axios.create({
        baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai': 'http://localhost:3000',
        // baseURL: "https://api.loglive.io",
        headers: {
          Authorization: `${this.$store.state.token}`,
          apikey: `${this.$store.state.orgToken}`,
        },
      });
      let result = await Api.post("/upload/document", obj)
      console.log('Removal Permit')
      this.pallet.permitDocumentId = result.data.id;
      this.pallet.permitDocument = result.data;
      console.log(this.pallet)
      await this.$API.updatePallet({
        id: this.pallet.id,
        permitDocumentId: this.pallet.permitDocumentId,
      })
      this.removalPermitList = []
      this.documentKey++;
      this.consignmentNoteAddModal = false
      this.removalPermitModal = false
      this.uploadingFile = false
      this.refreshKey++
    },
    updateDocument(event) {
      // this.$emit('updateDocument', event)
      let findDocument = this.documents.findIndex(x => x.id == event.id)
      if (findDocument > -1) {
        this.documents[findDocument] = event
      }
    },
    async viewAuditedDocument(doc) {
      this.auditDocument = doc;
      this.rotate = 0;
      this.auditModal = true;
      this.annotate();
    },
    async viewDocument(document) {
      this.documentLoading = true
      this.document = await this.$API.getShipmentDocumentTypeDetail(document.id)
      if (this.document.variations) {
        this.documentTypes = [this.document]
      }
      this.documentTasks = await this.$API.getShipmentDocumentTasks(document.id)
      this.documentUploads = await this.$API.getShipmentDocuments(this.$route.params.shipmentDocumentId);
      this.actions = await this.$API.getShipmentActions(this.$route.params.shipmentDocumentId);
      this.shipment = await this.$API.getSingleShipment(document.shipment.shipmentReference)
      this.bookings = await this.$API.getShipmentBookings(document.shipment.shipmentFileId)
      this.documentModal = true
      this.documentLoading = false
    },
    async viewRemovalPermit(item, farm) {
      this.documentItem = item;
      this.loadingPermit.push(item.id);
      this.permitModal = true;
      this.documentItem.url = null
      this.documentItem.url = await this.$API.getDocumentLink({
        id: item.id,
        key: item.key
      })
      if (farm) {
        this.documentItem.name = farm + '_Removal Permit.pdf'
      }
      this.itemKey++
      this.loadingPermit.splice(this.loadingPermit.indexOf(item.id), 1)
    },
    viewMedia(task) {
      this.task = task;
      this.mediaModal = true;
    },
    viewTask(task) {
      this.task = task;
      this.taskModal = true;
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false
      e.stopPropagation();
      this.driver.defineSteps(this.steps)
      this.driver.start()
    },
  },
};
</script>
<style scoped>
.v-card--reveal {
  border-radius: 20px;
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  z-index: 9999;
  position: absolute;
  width: 100%;
  cursor: pointer;
}
</style>
